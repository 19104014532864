export const environment = {
  production: true,
  FIREBASE_API_KEY: process.env['FIREBASE_API_KEY'],
  // Suggested code may be subject to a license. Learn more: ~LicenseLog:724924159.
  FIREBASE_AUTH_DOMAIN: process.env['FIREBASE_AUTH_DOMAIN'],
  FIREBASE_PROJECT_ID: process.env['FIREBASE_PROJECT_ID'],
  // Suggested code may be subject to a license. Learn more: ~LicenseLog:3765266061.
  FIREBASE_STORAGE_BUCKET: process.env['FIREBASE_STORAGE_BUCKET'],
  FIREBASE_MESSAGING_SENDER_ID: process.env['FIREBASE_MESSAGING_SENDER_ID'],
  FIREBASE_APP_ID: process.env['FIREBASE_APP_ID'],
  FIREBASE_MEASUREMENT_ID: process.env['FIREBASE_MEASUREMENT_ID'],

  COLLECTION_NAME: process.env['COLLECTION_NAME_PROD'],
  DETAILS_COLLECTION: process.env['DETAILS_COLLECTION_PROD'],

  IPIFY_END_POINT: process.env['IPIFY_END_POINT'],
  FAVICON_END_POINT: process.env['FAVICON_END_POINT'],

  TURNSTILE_SITE_KEY: process.env['TURNSTILE_SITE_KEY'],
  TURNSTILE_SECRET_KEY: process.env['TURNSTILE_SECRET_KEY'],
  TURNSTILE_SITE_VERIFY_ENDPOINT: process.env['TURNSTILE_SITE_VERIFY_ENDPOINT'],
};
