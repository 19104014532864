<div class="grid place-items-center h-screen px-6 md:px-0">
    <div class=" container mx-auto">
      <div class="w-3/5 md:w-2/5 lg:w-1/5 mx-auto my-12">
        <img src="trkbl_final_logo.svg" alt="trkbl logo">
      </div>
      <div class="flex justify-center text-2xl font-medium">
        
        Forgot Password?
      </div>
      <div class="flex justify-center text-gray-500">
        Enter your email and we will help you reset your password.
      </div>
      
      
      <form
        [formGroup]="forgotEmail"
        class="rounded-lg shadow-xl w-full pb-4 md:w-6/12 lg:w-4/12 md:mx-auto md:mb-12"
        (ngSubmit)="sendEmailforRecovery()"
      >
        <div class="flex flex-col m-4">
          <!-- <div class="text-5xl lg:text-6xl font-serif">trkbl</div> -->
           
          <div>
            <input
              type="email"
              placeholder="Enter email address"
              formControlName="email"
              class="border-2 p-4 rounded-lg mb-1 w-full lg:mt-3"
              required
            />
          </div>

          <div *ngIf="forgotEmail.get('email')?.hasError('required') && forgotEmail.get('email')?.touched">

            <div class="text-red-900">
              Email address is required!
            </div>

          </div>
          <div *ngIf="forgotEmail.get('email')?.hasError('email') && forgotEmail.get('email')?.touched">

            <div class="text-red-900">
              Please enter a valid email.
            </div>

          </div>

          <div *ngIf="mailSentSuccess" class="text-green-800 font-medium my-1">
            Reset mail sent successfully. Please check your inbox for password reset instructions.
          </div>
          
          
          
          
          
    
          <!-- <div *ngIf="showError" class="text-red-900">
            {{ errorMessage }}
          </div> -->
    
          <!-- <div class="cf-turnstile" data-sitekey="0x4AAAAAAA0r3iZBtyWcBs_J" data-theme="light"></div> -->
    
          <div *ngIf="!forgotEmail.valid; else valid_button"> 
            <button
              type="submit"
              disabled
              class="border-2 w-full border-blue-400 px-2 py-3 my-3 rounded-lg bg-blue-400 text-white font-bold hover:cursor-not-allowed"
            >
            Send Password Reset Link
            </button>
          </div>
          
          <ng-template #valid_button>
            
    
            <div *ngIf="sendingData; else yet_to_click" class="mx-auto">
                <!-- <button disabled class="border-2 w-full border-blue-400 px-2 py-1 rounded-full bg-blue-400 text-white font-bold hover:cursor-not-allowed"> -->
                    <span class="loader size-6"></span>
                
            </div>
            <ng-template #yet_to_click>
                <button
              type="submit"
              
              class="border-2 border-blue-600 px-2 py-3 my-3 rounded-lg bg-blue-600 text-white font-bold hover:bg-blue-800 hover:border-blue-800 hover:cursor-pointer"
            >
              Send Password Reset Link
            </button>
            </ng-template>
            
    
          </ng-template>

          <div
          class="mt-2 text-blue-600 font-medium items-center justify-end hover:text-blue-800 hover:cursor-pointer lg:mt-3"
        >
        <div class="flex" (click)="backToHome()">
          <div>
          
            <span class="material-symbols-outlined mr-2">
              arrow_back
              </span>
          </div>
          <div> 
            Back to login
          </div>
        </div>
        
        
          
        </div>
    
          
        </div>
      </form>
    </div>
    
    
    
  </div>
  
