<div class="container mx-auto p-5 md:py-5 md:px-6">
  <div class="flex flex-row items-center">
    <div class="w-9">
      <img [src]="favicon" alt="Redirect favicon">
    </div>
    <div class="flex flex-col ml-5 lg:ml-8">
      <div class="font-bold text-2xl">{{ titleString }}</div>
  <div class="text-xl">trkbl.com/{{ backlinkString }}</div>
    </div>
  </div>
  <div class="my-3 text-lg">
    <div class="mt-2 text-blue-600 hover:text-blue-800 hover:cursor-pointer hover:underline lg:mt-3 line-clamp-1">{{ redirectURL }}</div>
  <div class="grid grid-cols-1 md:grid-cols-2 my-3 md:my-2">
    <div class="text-md text-green-600 font-bold">Visits: {{ engagements }}</div>
    <div class="text-md text-green-600 font-bold">Unique Devices: {{ unique_engagements }}</div>
    
      
    
    
  </div>
  </div>
  
  



  
    <div class="container mx-auto">
      <div class="grid grid-col-1 lg:grid-cols-4 md:grid-cols-2 gap-3">
        <div class="border-2 rounded-xl bg-slate-100 mb-4">
          <canvas id="deviceData"></canvas>
        </div>
        <div class="border-2 rounded-xl bg-slate-100 mb-4">
          <canvas id="lastDayClicks"></canvas>
        </div>
        <div class="border-2 rounded-xl bg-slate-100 mb-4">
          <canvas id="osversion"></canvas>
        </div>
        <div class="border-2 rounded-xl bg-slate-100 mb-4">
          <canvas id="browser"></canvas>
        </div>
        <div class="border-2 rounded-xl bg-slate-100 mb-4">
          <canvas id="orientation"></canvas>
        </div>
        <div class="border-2 rounded-xl bg-slate-100 mb-4">
          <canvas id="device"></canvas>
        </div>
      </div>
    </div>
  
    
  
  
  



</div>






