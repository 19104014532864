import { Routes } from '@angular/router';
import { AppComponent } from './app.component';
import { HomeComponent } from './home/home.component';
import { AdminComponent } from './admin/admin.component';
import { LoginpageComponent } from './loginpage/loginpage.component';
import { CreateLinkComponent } from './createlink/createlink.component';
import { NotFoundError } from 'rxjs';
import { NotfoundComponent } from './notfound/notfound.component';
import { DetailsComponent } from './details/details.component';
import { AdminhomeComponent } from './adminhome/adminhome.component';
import { ForgotComponent } from './forgot/forgot.component';
import { HolderComponent } from './holder/holder.component';
import { ResetComponent } from './reset/reset.component';
import { authguardGuard } from './guards/authguard.guard';


export const routes: Routes = [
  // { path: '', component: HolderComponent },
  // { path: '**', component: NotfoundComponent }
  
  { path: '', component: LoginpageComponent },
  // { path: '**', component: NotfoundComponent },
  
  {
    path: 'admin',
    component: AdminhomeComponent,
    children: [
      {
        path: '',
        component: AdminComponent,
        canActivate: [authguardGuard]
      },
      {
        path: 'link', component: CreateLinkComponent , canActivate: [authguardGuard]
      },
      {
        path: ':id',
        component: DetailsComponent,
        canActivate: [authguardGuard]
      },
      
    ],
    canActivate: [authguardGuard]
  },
  
  { path: 'forgot', component: ForgotComponent },
  { path: 'reset', component:  ResetComponent},
  { path: 'error', component: NotfoundComponent },
  { path: ':id', component: HomeComponent },
];
