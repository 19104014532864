import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RedirectCommand, Route, Router, RouterOutlet } from "@angular/router";
import { FirebaseService } from "../services/firebase.service";
import { HttpClient } from "@angular/common/http";
import { AnyCatcher } from "rxjs/internal/AnyCatcher";
import { dateTimestampProvider } from "rxjs/internal/scheduler/dateTimestampProvider";
import { IP } from "../models/ip.models";
import { OtherservicesService } from "../services/otherservices.service";
import { DeviceDetectorService } from "ngx-device-detector";
import { DocumentData, serverTimestamp } from "firebase/firestore";
import firebase from "firebase/compat/app";
import { environment } from "../../environments/environment.development";


@Component({
    selector: 'app-home',
    standalone: true,
    // imports: [RouterOutlet],
    templateUrl: './home.component.html',
    styleUrl: './home.component.css'
})

export class HomeComponent implements OnInit {
    constructor(private activatedRoute: ActivatedRoute, private firebaseService: FirebaseService, private router: Router, private http: HttpClient, private otherService: OtherservicesService, private deviceService: DeviceDetectorService) { }


    // collectionName = 'backlinks_prod'
    // this is test for git ignore
    collectionName = environment.COLLECTION_NAME
    searchVal = 'backlink'
    deviceDetails = 'details_prod'
    theIPVal: Object | undefined



    ngOnInit(): void {

      const httpsEndPoint = environment.IPIFY_END_POINT
      if(httpsEndPoint == undefined)
        return

        this.http.get(httpsEndPoint).subscribe(val => {

            this.theIPVal = val

            
            
              
            
          });
        this.checkExistingLink()
        // this.updateDocs()
    }

    async checkExistingLink() {
        try {
          const backlinkData = this.activatedRoute.snapshot.paramMap.get('id');
          if(this.collectionName == undefined)
            return
          const docData = await this.firebaseService.multiQuery(this.collectionName, this.searchVal, backlinkData, 'deleted', false);
      
          if (docData['length'] == 0) {
            this.router.navigate(['/error']);
            return;
          }
      
          console.log('Running update docs');
          await this.updateDocs(docData[0]['id'], backlinkData);
      
          // Only after the updateDocs is done, navigate to the external URL
          window.location.href = docData[0]['data']['redirectURL'];  // Correct URL format with 'https://'
          
        } catch (e) {
          console.log(e);
        }
      }
      
      

    ipList: any

    async updateDocs(backlinkID: string, backlink: string | null) {
        try {
          console.log('Updating docs...');

          await this.updateUnique(this.theIPVal, backlink, backlinkID);
            await this.updateTheDetails(this.theIPVal, backlink);

          await this.updateTheBacklinks(backlinkID, 'engagements');
      
            if (this.deviceService.isDesktop()) {
              await this.updateTheBacklinks(backlinkID, 'desktop');
              console.log('Desktop');
              return;
            }
      
            if (this.deviceService.isMobile()) {
              await this.updateTheBacklinks(backlinkID, 'mobile');
              console.log('Mobile');
              return;
            }
      
            
              await this.updateTheBacklinks(backlinkID, 'tablet');
              console.log('Tablet');
      
          // your logic for getting IP and updating documents
          
      
          console.log('Docs updated successfully');
        } catch (err) {
          console.error('Error updating docs:', err);
        }
      }
      

    async updateTheDetails(val: any, backlinkData: string | null){

        console.log('Update details running')

        await this.firebaseService.addDataToCollection(this.deviceDetails, {
          // ip: this.testIP,
            ip: val["ip"],
            browser: this.deviceService.browser,
            device: this.deviceService.device,
            device_type: this.deviceService.deviceType,
            orientation: this.deviceService.orientation,
            os: this.deviceService.os,
            os_version: this.deviceService.os_version,
            userAgent: this.deviceService.userAgent,
            timestamp: serverTimestamp(),
            backlinkDetails: backlinkData
        })
    }



    async updateUnique(val: any, backlinkData: string | null, backlinkID: string){

        console.log('Update the unique running')
    
        const q = await this.firebaseService.multiQuery(this.deviceDetails, 'backlinkDetails', backlinkData, 'ip', val['ip'])
        console.log(q)
        console.log(q['length'])

        if(q['length'] > 0){
          return

        }

        await this.updateTheBacklinks(backlinkID, 'unique')



    }

    async updateTheBacklinks(docID: string, parameter: string){
      if(this.collectionName == undefined)
        return
      await this.firebaseService.incrementFirestore(this.collectionName, docID, parameter)
  }


}





