import { CommonModule } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterOutlet } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { DocumentData } from 'firebase/firestore';
import { StoredataService } from '../services/storedata.service';
import { CreateLinkComponent } from "../createlink/createlink.component";

@Component({
  selector: 'app-adminhome',
  standalone: true,
  imports: [RouterOutlet, CommonModule],
  templateUrl: './adminhome.component.html',
  styleUrl: './adminhome.component.css'
})
export class AdminhomeComponent implements OnInit{

  dropdown = false
  allTheDocs: DocumentData[] = []
  // collectionName = 'backlinks'
  showCreateLink = true


  constructor(private firebaseService: FirebaseService, private storeData: StoredataService, private router: Router, private activatedRoute: ActivatedRoute){}
  ngOnInit(): void {

    // if(this.activatedRoute.snapshot.routeConfig?.path == '/admin/link'){
    //   this.showCreateLink = false
    // }

    this.activatedRoute.url.subscribe(data => {
      console.log(data[0])
    })
    

    // console.log(this.activatedRoute.snapshot.routeConfig?.children[0])
    
    

    // throw new Error('Method not implemented.');
    // this.getLinks()
  }

  dropdownToggle(){
    this.dropdown = !this.dropdown
  }

  async getLinks(){
    
    // await this.storeData.getData()
    // console.log(this.allTheDocs)

  }

  goToLink(){
    this.router.navigate(['admin/link'])
  }

  goToAdmin(){
    this.router.navigate(['admin'])
  }

  async logOutProfile(){
    try{
      await this.firebaseService.logOut()
      this.router.navigate([''])
    }catch(e){

    }
  }

}
