import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, RouterOutlet } from '@angular/router';
import { OtherservicesService } from './services/otherservices.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { Details } from './models/details.model';
import { FirebaseService } from './services/firebase.service';
import { DeviceDetectorService, DeviceType } from 'ngx-device-detector';
import { serverTimestamp } from 'firebase/firestore';
import { environment } from '../environments/environment.development';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet],
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
})
export class AppComponent {
  title = 'Trkbl';
  inter: any;
  deviceInfo: any;
  ipAddress: any;
  details: Details | undefined;

  // constructor(private deviceService: DeviceDetectorService, private httpClient: HttpClient, private firebase: FirebaseService, private activatedRoute: ActivatedRoute){}s

  constructor() {
    console.log(environment.FIREBASE_API_KEY);
  }

  createComponent() {}
}
