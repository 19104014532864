
<img src="trkbl_final_logo.svg" class="w-3/5 md:w-2/5 lg:w-1/5 mx-auto mt-8 hover:cursor-pointer" (click)="goToHome()">

<div
        class="grid place-items-center shadow-xl border md:w-fit md:mx-auto my-12 rounded-lg p-6 mt-6 mx-6"
      >

      

        <img
          src="giphy1.webp"
          class="w-4/5 md:w-4/5 lg:w-full rounded-lg"
          alt="empty GIF"
        />
        <div class="font-medium text-4xl mt-8">
            Error 404: Not Found
        </div>
        <div class="text-lg text-center text-slate-600 font-bold">
          Uh oh! Seems like you have lost your way.
        </div>

        <button (click)="goToHome()"
          
          class="flex bg-blue-600 text-white hover:bg-blue-800 py-3 px-3 mt-4 rounded-lg font-bold"
        >
          <span class="material-symbols-outlined mr-2"> home </span>
          Go to Homepage
        </button>
      </div>