import { HttpClient, provideHttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { DeviceDetectorService } from 'ngx-device-detector';
import { map, retry } from 'rxjs';
import { FirebaseService } from './firebase.service';
import { Details } from '../models/details.model';

import { environment } from '../../environments/environment.development';

// require('dotenv').config()

@Injectable({
  providedIn: 'root',

})
export class OtherservicesService {

  constructor(private deviceService: DeviceDetectorService, private http: HttpClient, private firebase: FirebaseService) { }

  getIP(){

    const httpEndPoint = environment.IPIFY_END_POINT

    if(httpEndPoint == undefined)
      return

    if(this.http.get(httpEndPoint) == undefined)
      return

    return this.http.get(httpEndPoint)
  }

  getImage(domain: string){

    const faviconGrab = environment.FAVICON_END_POINT

    const httpEndPoint = faviconGrab + domain


  
      return this.http.get(httpEndPoint, {
        responseType: 'blob'
      })
    
    
  }

  getDomain(url: string){


    const newURL = new URL(url)
    return newURL.hostname
  }

  

  



}
