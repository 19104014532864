<div class="my-3 lg:my-12">
  <div *ngIf="!loading; else loading_temp">
    <div *ngIf="showTheGIF; else noGIF">
      <div
        class="grid place-items-center shadow-xl border md:w-fit md:mx-auto my-12 rounded-lg p-6 mt-6 mx-6"
      >
        <img
          src="giphy.webp"
          class="w-4/5 md:w-4/5 lg:w-full rounded-lg"
          alt="empty GIF"
        />
        <div class="text-lg text-center text-slate-600 font-bold mt-8">
          This place looks oddly empty. Let's start populating it.
        </div>

        <button
          (click)="moveToLink()"
          class="flex bg-blue-600 text-white hover:bg-blue-800 py-3 px-3 mt-8 rounded-lg font-bold"
        >
          <span class="material-symbols-outlined mr-2"> add </span>
          Create Link
        </button>
      </div>
    </div>
    <ng-template #noGIF>
      <div
        class="lg:grid lg:grid-cols-3 lg:gap-4 lg:flex-none container md:mx-auto"
      >
        <div *ngFor="let k of allTheDocs; index as i">
          <div
            class="flex flex-col border p-3 lg:p-0 m-3 lg:m-0 lg:p-5 md:p-6 shadow-md rounded-lg md:m-0 md:mx-auto lg:mx-auto md:my-6 md:w-4/5 hover:cursor-pointer hover:bg-slate-200"
            (click)="move(k)"
          >
            <div class="flex items-center">
              <div>
                <img [src]="favicon[i]" class="w-7" alt="Redirect favicon" />
              </div>
              <div class="ml-6">
                <div class="text-2xl font-bold lg:text-2xl">
                  {{ k["data"]["title"] }}
                </div>
                <div class="text-sm font-bold text-gray-600">
                  trkbl.com/{{ k["data"]["backlink"] }}
                </div>
              </div>
            </div>

            <div>
              <div class="flex mt-4 text-green-800">
                <div class="flex basis-1/2 lg:basis-1/2 items-center">
                  <span class="material-symbols-outlined"> trending_up </span>
                  <div class="text-sm ml-1 font-bold">
                    {{ k["data"]["engagements"] }} Visits
                  </div>
                </div>
                <div class="flex basis-1/2 lg:basis-1/2 items-center">
                  <span class="material-symbols-outlined">
                    calendar_today
                  </span>
                  <div class="text-sm ml-1 font-bold">
                    {{ k["data"]["timestamp"] | shortdatepipe }}
                  </div>
                </div>
              </div>
              <div class="flex items-center mt-3 text-green-800">
                <span class="material-symbols-outlined"> person </span>
                <div class="text-sm ml-1 font-bold">
                  {{ k["data"]["unique"] }} Unique Devices
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <ng-template #loading_temp>
    <div class="grid place-items-center mt-24">
      <span class="loader size-12 md:size-16 lg:size-24"></span>
    </div>
  </ng-template>
</div>
