import { Component } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FirebaseService } from '../services/firebase.service';
import { forkJoin } from 'rxjs';
import { CommonModule } from '@angular/common';
import { Route, Router } from '@angular/router';

@Component({
  selector: 'app-forgot',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './forgot.component.html',
  styleUrl: './forgot.component.css'
})
export class ForgotComponent {


  constructor(private firebaseService: FirebaseService, private router: Router){}


  sendingData = false
  mailSentSuccess = false

  forgotEmail = new FormGroup({
    email: new FormControl('', [
      Validators.required,
      Validators.email,
    ])
  })

  recoveryMail: any

  async sendEmailforRecovery(){
    this.sendingData = !this.sendingData
    

    try{
      this.recoveryMail = this.forgotEmail.value.email

      if(this.recoveryMail != null && this.recoveryMail != undefined){
        await this.firebaseService.sendResetMail(this.recoveryMail)
        this.mailSentSuccess = true
        this.sendingData = !this.sendingData
        
      }
      
      
    }catch(e){

      if(e == 'auth/invalid-email'){
        console.log('This email is invalid')
      }
      this.sendingData = !this.sendingData
      
      console.log(e)
    }
    
    
    // console.log()
  }

  backToHome(){
    this.router.navigate([''])
  }

}
