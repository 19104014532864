<div class="container mx-auto">
    <div class="flex-col">

        <div class="flex justify-between m-3 py-5 md:mx-0 rounded-lg px-3 md:px-6 shadow-xl border">
            <div class="flex items-center">
                <div *ngIf="!dropdown" class="material-symbols-outlined self-center md:hidden" (click)="dropdownToggle()">
                    menu
                </div>
                <div *ngIf="dropdown" class="material-symbols-outlined self-center md:hidden" (click)="dropdownToggle()">
                    close
                </div>
                <div class="w-24 ml-5 md:mx-0 lg:w-36 hover:cursor-pointer">
                    <img src="trkbl_final_logo.svg" alt="trkbl logo" (click)="goToAdmin()">
                </div>
            </div>
            <div class="hidden md:flex text-white">
                
                
                    <div class="flex px-3 py-3 font-bold rounded-lg bg-blue-600 items-center hover:bg-blue-800 hover:cursor-pointer" (click)="goToLink()">
                        <span class="material-symbols-outlined">
                            add_link
                            </span>
                        <div class="ml-2">Create Link</div>
                    </div>
                
                <div>
                    <div (click)="logOutProfile()" class="flex ml-2 px-3 py-3 font-bold rounded-lg bg-blue-600 items-center hover:bg-blue-800 hover:cursor-pointer" (click)="goToLink()">
                        <span class="material-symbols-outlined">
                            logout
                            </span>
                        <div class="ml-2">Log Out</div>
                    </div>
                </div>

                
                
            </div>
    
        </div>
        <div *ngIf="dropdown" class="flex-col mx-5 border rounded-lg">
            <div class="flex px-5 py-3 items-center">
                <span class="material-symbols-outlined">
                    add_link
                    </span>
                <div class="font-bold ml-3">
                    Create Link
                </div>
            </div>

            <div (click)="logOutProfile()" class="flex px-5 py-3 items-center">
                <span class="material-symbols-outlined">
                    logout
                    </span>
                <div class="font-bold ml-3">
                    Log Out
                </div>
            </div>
            
            
        </div>
    
    </div>
</div>


    


    <router-outlet></router-outlet>





