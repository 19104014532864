import { Component } from "@angular/core";
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from "@angular/forms";
import { Router } from "@angular/router";
import { FirebaseService } from "../services/firebase.service";
import { serverTimestamp } from "firebase/firestore";
import { QrCodeModule } from 'ng-qrcode';
import { CommonModule } from "@angular/common";
import { getAuth, onAuthStateChanged } from "firebase/auth";
import { environment } from "../../environments/environment.development";

@Component({
    selector: 'app-createlink',
    standalone: true,
    imports: [ReactiveFormsModule, QrCodeModule, CommonModule],
    templateUrl: './createlink.component.html',
    styleUrl: './createlink.component.css'
})

export class CreateLinkComponent {

    constructor(private router: Router, private firebaseService: FirebaseService) { }

    message = ''
    loading = false
    backlink = ''
    showQR = false
    showUTM = false
    redirectURL = ''
    // collectionName = 'backlinks_prod'
    collectionName = environment.COLLECTION_NAME
    searchVal = 'backlink'
    

    newLink = new FormGroup({
        backlink: new FormControl('',[
            Validators.required,
            Validators.minLength(6),
            Validators.pattern('^[A-Za-z0-9-_]+$')
        ]),
        redirectURL: new FormControl('',[
            Validators.required,
            Validators.pattern('^(https?|ftp):\/\/([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/[^\s]*)?$'
        )
        ]),
        title: new FormControl('',[
            Validators.required
        ]),
        utmcheck: new FormControl(this.showUTM),
        source: new FormControl({
            value: '',
            disabled: true
        }, 
            
            [
            Validators.required,
            Validators.pattern('^[A-Za-z0-9-_]+$')
        ]),
        medium: new FormControl({
            value: '',
            disabled: true
        },[
            Validators.required,
            Validators.pattern('^[A-Za-z0-9-_]+$')
        ]),
        cname: new FormControl({
            value: '',
            disabled: true
        }, [
            Validators.required,
            Validators.pattern('^[A-Za-z0-9-_]+$')
        ])
        
    })

    

    
// Suggested code may be subject to a license. Learn more: ~LicenseLog:3251097364.
// Suggested code may be subject to a license. Learn more: ~LicenseLog:808226679.
    lookVal = this.newLink

    changeToUTM(){
        this.showUTM = !this.showUTM

        if(this.showUTM){
            this.newLink.controls.cname.enable()
            this.newLink.controls.source.enable()
            this.newLink.controls.medium.enable()
        }else{
            this.newLink.controls.cname.disable()
            this.newLink.controls.source.disable()
            this.newLink.controls.medium.disable()
        }

        
    }



    async checkLink(){

        this.loading = !this.loading
        this.message = ''

        if(this.newLink.value.backlink == null){
            this.loading = !this.loading
            return
        }
        if(this.newLink.value.backlink == undefined){
            this.loading = !this.loading
            return
        }
        if(this.newLink.value.title == null){
            this.loading = !this.loading
            return
        }
        if(this.newLink.value.title == undefined){
            this.loading = !this.loading
            return
        }
        if(this.newLink.value.redirectURL == null){
            this.loading = !this.loading
            return
        }
        if(this.newLink.value.redirectURL == undefined){
            this.loading = !this.loading
            return
        }

        if(this.newLink.value.redirectURL.trim() == 'https://trkbl.com/'+this.newLink.value.backlink.trim()){
            this.loading = !this.loading
            this.message = 'The backlink and the redirect URL cannot be the same.'
            return
        }

        // console.log('Function ran successfully')
        
        try{
            const currentUserHere = await this.firebaseService.getCurrentUser()
            if(currentUserHere == null){
                return
            }

            if(this.collectionName == undefined)
                return

            const getExisting = await this.firebaseService.collectionQuery(this.collectionName, this.searchVal, this.lookVal.value.backlink, 'owner', currentUserHere.uid)
            if(getExisting["length"] > 0){
                this.loading = !this.loading
                this.message = 'The backlink already exists, please try with a new one.'
            }else{
                this.createNewLink()
            }
        }catch(e){
            this.loading = !this.loading
            this.message = 'Something went wrong. Please try again.'
        }

    }

    async createNewLink(){

        try{

            const auth = getAuth()
            onAuthStateChanged(auth, async (user) => {
                // if(user){

                    

                    if(this.showUTM){

                        if(this.newLink.value.cname == null){
                            this.loading = !this.loading
                            return
                        }
                        if(this.newLink.value.cname == undefined){
                            this.loading = !this.loading
                            return
                        }
                        if(this.newLink.value.source == null){
                            this.loading = !this.loading
                            return
                        }
                        if(this.newLink.value.source == undefined){
                            this.loading = !this.loading
                            return
                        }
                        if(this.newLink.value.medium == null){
                            this.loading = !this.loading
                            return
                        }
                        if(this.newLink.value.medium == undefined){
                            this.loading = !this.loading
                            return
                        }

                        this.redirectURL = this.newLink.value.redirectURL?.trim() +
                        '?utm_source=' +
                        this.newLink.value.source +
                        '&utm_medium=' +
                        this.newLink.value.medium +
                        '&utm_campaign=' +
                        this.newLink.value.cname

                        console.log(this.redirectURL)

                        

                    }else{

                        if(this.newLink.value.redirectURL?.trim() != undefined){
                            this.redirectURL = this.newLink.value.redirectURL?.trim()
                        }

                        
                    }
                    




                    if(this.collectionName == undefined)
                        return

                    await this.firebaseService.addDataToCollection(this.collectionName, {
                        backlink: this.newLink.value.backlink?.trim(),
                        redirectURL: this.redirectURL,
                        title: this.newLink.value.title?.trim(),
                        timestamp: serverTimestamp(),
                        owner: user?.uid,
                        desktop: 0,
                        engagements: 0,
                        mobile: 0,
                        tablet: 0,
                        unique: 0,
                        deleted: false
                    })
                // }
            })

            this.loading = !this.loading
            this.showQR = !this.showQR
            this.message = 'Link successfully created!'
            if(this.newLink.value.backlink != null){
                this.backlink = 'trkbl.com/' + this.newLink.value.backlink.trim()
            }
            
            // console.log(fileUpload.id)
            // this.navigateToHome()
        }catch(e){
            this.loading = !this.loading
            this.message = 'Something went wrong. Please try again.'
        }

    }

    navigateToHome() {
        this.router.navigate(['/']);
    }
}

