import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, inject, OnInit, Output, EventEmitter } from '@angular/core';
import {
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Router, RouterLink, RouterLinkActive, RouterOutlet } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';

import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { environment } from '../../environments/environment.development';
import { HttpClient } from '@angular/common/http';


@Component({
  selector: 'app-login',
  standalone: true,
  imports: [ReactiveFormsModule, RouterLink, RouterLinkActive, CommonModule],
  templateUrl: './loginpage.component.html',
  styleUrl: './loginpage.component.css',
})




export class LoginpageComponent implements OnInit, AfterViewInit {

  @Output() tokenValidated = new EventEmitter<string>()


  constructor(private firebaseService: FirebaseService, private router: Router, private http: HttpClient) {}
  ngOnInit(): void {
    onAuthStateChanged(getAuth(), user => {
      if(user){
        this.router.navigate(['admin'])
      }
    })
  }
  ngAfterViewInit(): void {

    
    

    
  }
  
  private siteKey = environment.TURNSTILE_SITE_KEY
  



  sendingData = false
  showError = false
  email = '';
  password = '';
  errorMessage = ''
  

  loginForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email]),
    password: new FormControl('', [
      Validators.required,
      Validators.minLength(8),
    ]),
  });

  async initiateLogin() {
    if (this.loginForm.value.email == null) {
      return;
    }
    if (this.loginForm.value.email == undefined) {
      return;
    }
    if (this.loginForm.value.password == null) {
      return;
    }
    if (this.loginForm.value.password == undefined) {
      return;
    }

    this.sendingData = !this.sendingData
    this.showError = false

    this.email = this.loginForm.value.email.trim();
    this.password = this.loginForm.value.password.trim();

    

    if (this.loginForm.valid) {

      

        try{
          console.log('Trying to log in')
          await this.firebaseService.logIn(this.email, this.password)
          this.router.navigate(['/admin'])
        }catch(e: any){
          this.sendingData = !this.sendingData
          this.showError = !this.showError
          if(e.code == 'auth/invalid-credential'){
            this.errorMessage = 'Email or password is incorrect.'
            return
          }

          if(e.code == 'auth/invalid-email'){
            this.errorMessage = 'The provided email is invalid. Please check.'
            return
          }

          if(e.code == 'auth/too-many-requests'){
            this.errorMessage = 'Maximun log in attempts exhausted. Please try again later.'
            return
          }

          this.errorMessage = 'Something went wrong. Please try again.'

        }
        
        
        
      
      
      // console.log(result.user)
      
      
    }
  }
}
