import { Color } from './../../../node_modules/@kurkle/color/dist/color.d';
import { Component, OnInit } from '@angular/core';
// import { NgChartsModule } from 'ngx-chartjs'
import { NgxChartsModule } from '@swimlane/ngx-charts';
// import { NgChartsModule } from 'chart.js';
import Chart from 'chart.js/auto';
import { FirebaseService } from '../services/firebase.service';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { DocumentData, serverTimestamp, Timestamp } from 'firebase/firestore';
import { OtherservicesService } from '../services/otherservices.service';
import { TrimstringPipe } from '../pipes/trimstring.pipe';
import { environment } from '../../environments/environment.development';

@Component({
  selector: 'app-details',
  standalone: true,
  imports: [],
  templateUrl: './details.component.html',
  styleUrl: './details.component.css',
})
export class DetailsComponent implements OnInit {


  collectionName = environment.COLLECTION_NAME
  detailsCollection = environment.DETAILS_COLLECTION




  chart: any = [];
  // collectionName = 'backlinks_prod';
  
  searchVal = 'backlink';
  docVal: DocumentData = [];
  // detailsCollection = 'details_prod';
  redirectURL = ''
  titleString = ''
  engagements = 0
  unique_engagements = 0
  backlinkString = ''
  favicon: any

  
  

  constructor(
    private firebaseService: FirebaseService,
    private activatedRoute: ActivatedRoute,
    private otherService: OtherservicesService
  ) {}

  ngOnInit(): void {
    this.checkBackLink();
    
  }

  async checkBackLink() {
    const backlink = this.activatedRoute.snapshot.paramMap.get('id');
    if(this.collectionName == undefined)
      return
    // console.log(backlink)
    const q = await this.firebaseService.multiQuery(
      this.collectionName,
      this.searchVal,
      backlink,
      'deleted',
      false
    );
    if (q['length'] == 0) {
      console.log('No value found!');
      return;
    }

    console.log(this.engagements)

    this.docVal = q[0];
    console.log(this.docVal)

    

    this.titleString = this.docVal['data']['title']
    this.redirectURL = this.docVal['data']['redirectURL']
    this.engagements = this.docVal['data']['engagements']
    this.backlinkString = this.docVal['data']['backlink']
    console.log(this.engagements)
    this.unique_engagements = this.docVal['data']['unique']

    const domain = this.otherService.getDomain(this.redirectURL)
    const data = this.otherService.getImage(domain)
    data.subscribe((res: any) => {
      let reader = new FileReader()
      reader.addEventListener('load', () => {
        this.favicon = reader.result
      }, false)
      if(res){
        reader.readAsDataURL(res)
      }
    })


    // console.log(this.docVal['data']['mobile']);
    const clicksData = await this.getClicksPerDay(backlink, this.docVal);
    this.createCharts(clicksData, this.docVal);
  }

  async getClicksPerDay(backlink: string | null, docVal: DocumentData) {
    const daysCount: number[] = [];
    const dates: number[] = [];
    const perdaymillisecond = 86399999;
    const todayDate = new Date();
    const todayDateStart = new Date(
      todayDate.getFullYear(),
      todayDate.getMonth(),
      todayDate.getDate()
    );

    let todayDateMil = todayDate.getTime();
    let todayDateStartMil = todayDateStart.getTime();

    dates.push(new Date(todayDateStartMil).getDate());

    if(this.detailsCollection == undefined)
      return

    for (let i = 0; i < 7; i++) {
      const dataCount = await this.firebaseService.multiqueryDifferential(
        this.detailsCollection,
        'backlinkDetails',
        backlink,
        'timestamp',
        Timestamp.fromMillis(todayDateStartMil),
        'timestamp',
        Timestamp.fromMillis(todayDateMil)
      );
      // console.log(dataCount);
      daysCount.push(dataCount);
      // console.log(daysCount);
      todayDateMil = todayDateStartMil - 1;

      todayDateStartMil = todayDateMil - perdaymillisecond;
      // const dateFormat = new Date(todayDateStartMil)
      if (i != 6) {
        dates.push(new Date(todayDateStartMil).getDate());
      }

      // console.log(dateFormat)
      // console.log('end date: ', new Date(todayDateMil), '\n', 'start date: ', new Date(todayDateStartMil))
    }

    const os:number[] = []
    const iOS = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'os', 'iOS')
    os.push(iOS)
    const Android = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'os', 'Android')
    os.push(Android)
    const Windows = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'os', 'Windows')
    os.push(Windows)
    os.push(docVal['data']['engagements'] - iOS - Android - Windows)


    const device:number[] = []
    const mac = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'device', 'Mac')
    device.push(mac)
    const android = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'device', 'Android')
    device.push(android)
    const iphone = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'device', 'iPhone')
    device.push(iphone)
    const samsung = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'device', 'Samsung')
    device.push(samsung)
    const lg = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'device', 'LG')
    device.push(lg)
    const sony = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'device', 'Sony')
    device.push(sony)
    device.push(docVal['data']['engagements'] - mac - android - iphone - samsung - lg - sony)





    const browser:number[] = []
    const msedgechromium = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'browser', 'MS-Edge-Chromium')
    const msedge = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'browser', 'MS-Edge')
    browser.push(msedgechromium + msedge)
    const chrome = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'browser', 'Chrome')
    browser.push(chrome)
    const safari = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'browser', 'Safari')
    browser.push(safari)
    const firefox = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'browser', 'Firefox')
    browser.push(firefox)
    browser.push(docVal['data']['engagements'] - msedgechromium - msedge - chrome - safari - firefox)

    const orientation:number[] = []
    const portr = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'orientation', 'portrait')
    orientation.push(portr)
    const lands = await this.firebaseService.multiqueryDifferentialSm(this.detailsCollection, 'backlinkDetails', backlink, 'orientation', 'landscape')
    orientation.push(lands)
    // orientation.push(docVal['data']['engagements'] - portr - lands)

    // console.log(dates)

    return {
      dates: dates,
      count: daysCount,
      os: os,
      browser: browser,
      device: device,
      orientation: orientation
    }

    // for(let i = 0; i < 7; i++){
    // let lastdateMil = startdateMil - 1
    // console.log(new Date(lastdateMil))
    // let lastdateMilstart = lastdateMil - perdaymillisecond
    // console.log(new Date(lastdateMilstart))

    // const q = this.firebaseService.getallDocs('testbed')
    // console.log(q['length'])
    // startdateMil = lastdateMilstart

    // }

    // const enddateSecs = endDate['seconds']
    // const weekSeconds = enddateSecs - (7*24*60*60)
    // const startDate = Timestamp.fromMillis(weekSeconds*1000)
  }

  createCharts(clicksData: any, docData: DocumentData) {
    new Chart('deviceData', {
      type: 'doughnut',
      options: {
        layout: {
          padding:10
        },
        plugins: {
          title: {
            display: true,
            text: 'Device Distribution',
            font: {
              size: 17
            }
          }
        }
      },
      data: {
        labels: ['Desktop', 'Mobile', 'Tablet', 'Others'],
        datasets: [
          {
            label: '# of devices',
            data: [
              this.docVal['data']['desktop'],
              this.docVal['data']['mobile'],
              this.docVal['data']['tablet'],
              this.docVal['data']['engagements'] -
                this.docVal['data']['desktop'] -
                this.docVal['data']['mobile'] -
                this.docVal['data']['tablet'],
            ],
            borderWidth: 1,
          },
        ],
      },
    });

    new Chart('lastDayClicks', {
      type: 'bar',
      options: {
        layout: {
          padding:10
        },
        plugins: {
          title: {
            display: true,
            text: 'Past 7 Days Engagement',
            font: {
              size: 17
            }
          }
        }
      },
      data: {
        labels: clicksData['dates'],
        datasets: [
          {
            label: '# of Engagements',
            data: clicksData['count'],
            borderWidth: 1,
          },
        ],
      },
    });

    new Chart('osversion', {
      type: 'doughnut',
      options: {
        layout: {
          padding:10
        },
        plugins: {
          title: {
            display: true,
            text: 'Operating System Distribution',
            font: {
              size: 17
            }
          }
        }
      },
      data: {
        labels: ['iOS', 'Android', 'Windows', 'Others'],
        datasets: [
          {
            label: '# of devices',
            data: clicksData['os'],
            borderWidth: 1,
          },
        ],
      },
    });

    new Chart('browser', {
      type: 'doughnut',
      options: {
        layout: {
          padding:10
        },
        plugins: {
          title: {
            display: true,
            text: 'Browser Distribution',
            font: {
              size: 17
            }
          }
        }
      },
      data: {
        labels: ['MS-Edge', 'Chrome', 'Safari', 'Firefox', 'Others'],
        datasets: [
          {
            label: '# of devices',
            data: clicksData['browser'],
            borderWidth: 1,
          },
        ],
      },
    });

    new Chart('device', {
      type: 'doughnut',
      options: {
        layout: {
          padding:10
        },
        plugins: {
          title: {
            display: true,
            text: 'Manufacturer Distribution',
            font: {
              size: 17
            }
          }
        }
      },
      data: {
        labels: ['Mac', 'Android', 'iPhone', 'Samsung', 'LG', 'Sony', 'Others'],
        datasets: [
          {
            label: '# of devices',
            data: clicksData['device'],
            borderWidth: 1,
          },
        ],
      },
    });

    new Chart('orientation', {
      type: 'doughnut',
      options: {
        layout: {
          padding:10
        },
        plugins: {
          title: {
            display: true,
            text: 'Orientation Distribution',
            font: {
              size: 17
            }
          }
        }
      },
      data: {
        labels: ['Portrait', 'Landscape'],
        datasets: [
          {
            label: '# of devices',
            data: clicksData['orientation'],
            borderWidth: 1,
          },
        ],
      },
    });


  }
}

// customColors = ["#1d4b96", "#1d4b96", "#1d4b96"]
