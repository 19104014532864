import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FirebaseApp } from 'firebase/app';
import { FirebaseService } from '../services/firebase.service';
import { DocumentData, serverTimestamp } from 'firebase/firestore';
import { DeviceDetectorService } from 'ngx-device-detector';
import { CommonModule } from '@angular/common';
import { RouteReuseStrategy, Router, RouterOutlet } from '@angular/router';
import { ShortdatepipePipe } from "../pipes/shortdatepipe.pipe";
import { StoredataService } from '../services/storedata.service';
import { CustomRouteReuseStrategy } from '../models/routereuse';
import { OtherservicesService } from '../services/otherservices.service';


import { environment } from '../../environments/environment.development';




@Component({
  selector: 'app-admin',
  standalone: true,
  imports: [CommonModule, ShortdatepipePipe],
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.css',
  
})
export class AdminComponent implements OnInit, OnDestroy {
  
  // collectionName = process.env['COLLECTION_NAME']
  // collectionName = 'backlinks_prod';
  collectionName = environment.COLLECTION_NAME;
  searchVal = 'backlink';
  lookVal = 'newlink';
  // newLink = 'somenewlink'
  redirectLink = 'redirectlink';
  deviceType = '';
  dropdown = false;
  allTheDocs: DocumentData[] = []

  loading = true
  showTheGIF = true

  // favicon: string | ArrayBuffer | null = []
  favicon = new Array()

  


  // @Input() allBacklinks: DocumentData[] = []

  constructor(
    private firebaseService: FirebaseService,
    private router: Router,
    private otherService: OtherservicesService
  ) {}
  ngOnDestroy(): void {
    console.log('Component destroyed!')
    this.allTheDocs.length = 0
  }
  
  ngOnInit(): void {
    // throw new Error("Method not implemented.");
    this.firebaseService.getCurrentUser().then((user) => {
      if(user != null){
        this.getLinks(user.uid)
      }
      
    })
    
    // console.log('Component created!')
    // console.log(this.storeData.theDocs)
  }


  async getLinks(uid: string){

    if(this.collectionName == undefined)
      return

    this.allTheDocs = await this.firebaseService.getallDocs(this.collectionName, 'owner', uid, 10)
    this.allTheDocs.forEach(data => {

      const redirectURL = data['data']['redirectURL']
      console.log(redirectURL)

      const domain = this.otherService.getDomain(redirectURL)
      this.favicon.push('http://' + domain + '/favicon.ico')
      // console.log(domain + '/favicon.ico')
    // const img = this.otherService.getImage(domain)
    // img.subscribe((res: any) => {
    //   let reader = new FileReader()
    //   reader.addEventListener('load', () => {
    //     this.favicon.push = reader.result
    //   }, false)
    //   console.log(this.favicon.size)
    //   if(res){
    //     reader.readAsDataURL(res)
    //   }
    // })
      // console.log(data['data'])
    })
    this.loading = !this.loading
    if(this.allTheDocs.length > 0){
      // this.loading = false
      this.showTheGIF = false
    }
    

    console.log(this.allTheDocs.length)
  }

  move(k: DocumentData){
    if(this.allTheDocs.length > 0){
      this.router.navigate(['/admin/'+k['data']['backlink']])
    }
    
  }

  

  dropdownToggle() {
    this.dropdown = !this.dropdown;
  }

  moveToLink(){
    this.router.navigate(['admin/link'])
  }
}





