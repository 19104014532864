import { Pipe, PipeTransform } from '@angular/core';
import { Timestamp } from 'firebase/firestore';

@Pipe({
  name: 'shortdatepipe',
  standalone: true
})
export class ShortdatepipePipe implements PipeTransform {

  transform(value: Timestamp): string {
    const dateVal =  value.toMillis()
    const dateVal1 = new Date(dateVal)
    //const dateVal2 = new Date(dateVal1.getFullYear(), dateVal1.getMonth(), dateVal1.getDate())
    return dateVal1.getDate() + '/' + (dateVal1.getMonth() + 1) + '/' + dateVal1.getFullYear()
  }

}
