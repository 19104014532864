<div class="grid place-items-center h-screen px-6 md:px-0">
  <div>
    <div class="w-3/5 md:w-2/5 lg:w-1/5 mx-auto my-12">
      <img src="trkbl_final_logo.svg" alt="trkbl logo">
    </div>

    <div class="flex justify-center text-2xl font-medium">
        
      Log In
    </div>
    <div class="flex justify-center text-gray-500">
      Enter your email and password to log into your account.
    </div>
    
    
    <form
      [formGroup]="loginForm"
      class="rounded-lg shadow-xl w-full py-4 md:w-6/12 lg:w-4/12 md:mx-auto md:mb-12"
      (submit)="initiateLogin()"
    >
      <div class="flex flex-col m-4">
        <!-- <div class="text-5xl lg:text-6xl font-serif">trkbl</div> -->
         
        <div>
          <input
            type="text"
            placeholder="Email"
            formControlName="email"
            class="border-2 px-4 py-4 rounded-lg mt-2 w-full lg:mt-3"
            required
          />
        </div>
        <div
          *ngIf="
            loginForm.get('email')?.hasError('required') &&
            loginForm.get('email')?.touched
          "
          class="text-red-900"
        >
          Email address is required!
        </div>
        <div
          *ngIf="
            loginForm.get('email')?.hasError('email') &&
            loginForm.get('password')?.touched
          "
          class="text-red-900"
        >
          Please enter a valid email.
        </div>
        <div>
          <input
            type="password"
            placeholder="Password"
            formControlName="password"
            class="border-2 px-4 py-4 p-2 my-2 rounded-lg w-full lg:my-3"
            required
          />
        </div>
        
        <div
          *ngIf="
            loginForm.get('password')?.hasError('required') &&
            loginForm.get('password')?.touched
          "
          class="text-red-900"
        >
          Password is required!
        </div>
        <div *ngIf="loginForm.get('password')?.hasError('minlength')" class="text-red-900">
          Password is too short!
        </div>
  
        <div *ngIf="showError" class="text-red-900">
          {{ errorMessage }}
        </div>
  
        
          <!-- The following line controls and configures the Turnstile widget. -->
          <div class="cf-turnstile" data-sitekey="0x4AAAAAAA1MmgOZTMMmlB9r" data-theme="light"></div>
          <!-- end. -->
        
  
        <div *ngIf="!loginForm.valid; else valid_button">
          <button
            type="submit"
            disabled
            class="border-2 w-full border-blue-400 px-2 py-3 rounded-lg bg-blue-400 text-white font-bold hover:cursor-not-allowed"
          >
            Log In
          </button>
        </div>
        
        <ng-template #valid_button>
          
  
          <div *ngIf="sendingData; else yet_to_click" class="mx-auto">
              <!-- <button disabled class="border-2 w-full border-blue-400 px-2 py-1 rounded-full bg-blue-400 text-white font-bold hover:cursor-not-allowed"> -->
                  <span class="loader size-6"></span>
              
          </div>
          <ng-template #yet_to_click>
              <button
            type="submit"
            
            class="border-2 border-blue-600 px-2 py-3 rounded-lg bg-blue-600 text-white font-bold hover:bg-blue-800 hover:border-blue-800 hover:cursor-pointer"
          >
            Log In
          </button>
          </ng-template>
          
  
        </ng-template>
  
        <div
          class="mt-2 text-blue-600 font-medium hover:text-blue-800 hover:cursor-pointer lg:mt-3"
        >
          <a routerLink="forgot" routerLinkActive="router-link-active"
            >Forgot Password?</a
          >
        </div>
      </div>
    </form>
  </div>
  
  
  
</div>
