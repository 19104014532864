import { Injectable } from '@angular/core';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class StoredataService {

  constructor(private firebaseService: FirebaseService) { }

  theDocs: any = []
  

  // async getData(){
  //   const allDocs = await this.firebaseService.getallDocs("backlinks", 10)
  //   console.log(allDocs)
  //   this.theDocs = allDocs
    
  // }

  outputData(){
    return this.theDocs
  }

}
