<form
  (submit)="checkLink()"
  [formGroup]="newLink"
  class="flex flex-col justify-center items-center mx-3 my-7 md:mx-0 md:my-7 md:w-3/5 lg:w-2/5 md:mx-auto border border rounded-lg px-5 py-5 shadow-xl"
>
  <div class="font-medium text-2xl">Create New Link</div>

  <input
    type="text"
    class="w-full border-2 px-4 py-4 rounded-lg mt-2"
    placeholder="Enter link title"
    formControlName="title"
  />
  <div
    *ngIf="
      newLink.get('title')?.hasError('required') &&
      newLink.get('title')?.touched
    "
    class="text-red-900 font-medium w-full"
  >
    Title is required!
  </div>

  <input
    type="text"
    class="w-full border-2 px-4 py-4 rounded-lg mt-2"
    placeholder="Enter backlink"
    formControlName="backlink"
  />

  <div
    *ngIf="
      newLink.get('backlink')?.hasError('required') &&
      newLink.get('backlink')?.touched
    "
    class="text-red-900 font-medium w-full"
  >
    Backlink is required!
  </div>
  <div
    *ngIf="
      newLink.get('backlink')?.hasError('pattern') &&
      newLink.get('backlink')?.touched
    "
    class="text-red-900 font-medium w-full"
  >
    Invalid backlink. Backlinks can contain alphabets, numbers, hyphen and
    underscore only.
  </div>
  <div
    *ngIf="
      newLink.get('backlink')?.hasError('minlength') &&
      newLink.get('backlink')?.touched
    "
    class="text-red-900 font-medium w-full"
  >
    Backlink must be atleast 6 characters long.
  </div>

  <input
    type="text"
    class="w-full border-2 px-4 py-4 rounded-lg mt-2"
    placeholder="Enter redirectURL"
    formControlName="redirectURL"
  />

  <div
    *ngIf="
      newLink.get('redirectURL')?.hasError('required') &&
      newLink.get('redirectURL')?.touched
    "
    class="text-red-900 font-medium w-full"
  >
    RedirectURL is required!
  </div>
  <div
    *ngIf="
      newLink.get('redirectURL')?.hasError('pattern') &&
      newLink.get('redirectURL')?.touched
    "
    class="text-red-900 font-medium w-full"
  >
    Invalid URL. Please enter a valid URL.
  </div>

  

  <div class="flex">
    <input
      type="checkbox"
      formControlName="utmcheck"
      (change)="changeToUTM()"
    />
    <div class="ml-3">Integrate UTM parameters</div>
  </div>

  <!-------------------------------- UTM Parameters ----------------------------->

  <div *ngIf="showUTM" class="w-full">
    <input
      type="text"
      class="w-full border-2 px-4 py-4 rounded-lg"
      placeholder="Campaign Source"
      formControlName="source"
    />
    <div
      *ngIf="
        newLink.get('source')?.hasError('required') &&
        newLink.get('source')?.touched
      "
      class="text-red-900 font-medium w-full"
    >
      Campaign source is required!
    </div>

    <div
      *ngIf="
        newLink.get('source')?.hasError('pattern') &&
        newLink.get('source')?.touched
      "
      class="text-red-900 font-medium w-full"
    >
      Campaign source can only contain alphabets, numbers, hypen and underscore.
    </div>

    <input
      type="text"
      class="w-full border-2 px-4 py-4 rounded-lg mt-2"
      placeholder="Campaign Medium"
      formControlName="medium"
    />
    <div
      *ngIf="
        newLink.get('medium')?.hasError('required') &&
        newLink.get('medium')?.touched
      "
      class="text-red-900 font-medium w-full"
    >
      Campaign medium is required!
    </div>

    <div
      *ngIf="
        newLink.get('medium')?.hasError('pattern') &&
        newLink.get('medium')?.touched
      "
      class="text-red-900 font-medium w-full"
    >
      Campaign medium can only contain alphabets, numbers, hypen and underscore.
    </div>

   

    <input
      type="text"
      class="w-full border-2 px-4 py-4 rounded-lg mt-2 lg:mt-3"
      placeholder="Campaign Name"
      formControlName="cname"
    />

    <div
    *ngIf="
      newLink.get('cname')?.hasError('required') &&
      newLink.get('cname')?.touched
    "
    class="text-red-900 font-medium w-full"
  >
    Campaign name is required!
  </div>

  <div
    *ngIf="
      newLink.get('cname')?.hasError('pattern') &&
      newLink.get('cname')?.touched
    "
    class="text-red-900 font-medium w-full"
  >
    Campaign source can only contain alphabets, numbers, hypen and underscore.
  </div>
  </div>

  <div class="mt-3 font-medium text-green-900">
    {{ message }}
  </div>



  <div *ngIf="!showQR">
    <div *ngIf="!loading; else spinnerAnimation">
      <div *ngIf="newLink.valid; else newLinkNotValid">
        <button
          type="submit"
          class="border-2 border-blue-600 mt-5 px-2 py-3 rounded-lg bg-blue-600 text-white font-bold hover:bg-blue-800 hover:border-blue-800 hover:cursor-pointer"
        >
          Create Link & Generate QR Code!
        </button>
      </div>
      <ng-template #newLinkNotValid>
        <button
          type="submit"
          disabled
          class="border-2 border-blue-400 mt-5 px-2 py-3 rounded-lg bg-blue-400 text-white font-bold hover:cursor-not-allowed"
        >
          Create Link & Generate QR Code!
        </button>
      </ng-template>
    </div>
    <ng-template #spinnerAnimation>
      <span class="loader size-6"></span>
    </ng-template>
  </div>
</form>
<div
  *ngIf="showQR"
  class="flex flex-col items-center justify-center my-6 rounded-lg"
>
  <qr-code [value]="backlink" size="250" />
  <div>
    {{ backlink }}
  </div>
  <div>
    {{ redirectURL }}
  </div>
</div>
