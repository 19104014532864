import { CanActivateFn, Router } from '@angular/router';
import { FirebaseService } from '../services/firebase.service';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { user } from '@angular/fire/auth';
import { inject, Injectable } from '@angular/core';
import { from, map } from 'rxjs';

export const authguardGuard: CanActivateFn = (route, state) => {

  const auth = getAuth(); // Firebase Auth instance
  const router = inject(Router);

  // Convert the onAuthStateChanged callback into an Observable
  return from(
    new Promise<boolean>((resolve) => {
      auth.onAuthStateChanged((user) => {
        if (user) {
          resolve(true); // User is authenticated
        } else {
          resolve(false); // User is not authenticated
        }
      });
    })
  ).pipe(
    map((isAuthenticated: any) => {
      if (!isAuthenticated) {
        router.navigate(['']); // Redirect to login if not authenticated
      }
      return isAuthenticated;
    })
  );
  

};
