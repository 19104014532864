import { inject, Injectable } from '@angular/core';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';
import {
  confirmPasswordReset,
  sendPasswordResetEmail,
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  getAuth,
} from 'firebase/auth';
import {
  DocumentData,
  addDoc,
  collection,
  doc,
  getCountFromServer,
  getDoc,
  getDocs,
  getFirestore,
  increment,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment.development';

const firebaseConfig = {
  apiKey: environment.FIREBASE_API_KEY,
  authDomain: environment.FIREBASE_AUTH_DOMAIN,
  projectId: environment.FIREBASE_PROJECT_ID,
  storageBucket: environment.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: environment.FIREBASE_MESSAGING_SENDER_ID,
  appId: environment.FIREBASE_APP_ID,
  measurementId: environment.FIREBASE_MEASUREMENT_ID,
};

// Initialize Firebase

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const db = getFirestore(app);
const auth = getAuth(app);

// let isloggedinStatus = false

// const COLLECTION_NAME = 'backlinks'

@Injectable({
  providedIn: 'root',
})
export class FirebaseService {
  constructor(private router: Router) {}

  async addDataToCollection(collectionName: string, details: any) {
    const uploadedData = await addDoc(collection(db, collectionName), details);
    return uploadedData;
  }

  async collectionQuery(
    collectionName: string,
    searchVal: string,
    lookVal: any,
    userTitle: string,
    userID: string
  ) {
    let collectionDocs: DocumentData[] = [];

    const colName = collection(db, collectionName);

    // Suggested code may be subject to a license. Learn more: ~LicenseLog:4279745158.
    const q = query(
      colName,
      where(searchVal, '==', lookVal),
      where(userTitle, '==', userID)
    );

    // (await getDocs(q)).size > 0 ? console.log("Found") : console.log("Not Found")
    const querySnap = await getDocs(q);
    querySnap.forEach((doc) => {
      collectionDocs['push']({
        id: doc.id,
        data: doc.data(),
      });
      // console.log(doc.data())
    });
    return collectionDocs;
  }

  async incrementFirestore(
    collectionName: string,
    docID: string,
    parameter: string
  ) {
    const docRef = doc(db, collectionName, docID);
    await updateDoc(docRef, {
      [parameter]: increment(1),
    });
  }

  async multiQuery(
    collectionName: string,
    searchVal1: string,
    lookVal1: string | null,
    searchVal2: string,
    lookVal2: any
  ) {
    let queryDocs: DocumentData[] = [];

    const colName = collection(db, collectionName);
    const q = query(
      colName,
      where(searchVal1, '==', lookVal1),
      where(searchVal2, '==', lookVal2),
      limit(1)
    );
    const querySnap = await getDocs(q);
    querySnap.forEach((doc) => {
      queryDocs['push']({
        id: doc.id,
        data: doc.data(),
      });
      // console.log(doc.data())
    });
    return queryDocs;
  }

  async multiqueryDifferential(
    collectionName: string,
    backlinkID: string,
    backlink: string | null,
    searchVal1: string,
    lookVal1: any,
    searchVal2: string,
    lookVal2: any
  ) {
    const colName = collection(db, collectionName);
    const q = query(
      colName,
      where(backlinkID, '==', backlink),
      where(searchVal1, '>=', lookVal1),
      where(searchVal2, '<=', lookVal2)
    );
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
    // const snapData = await getDocs(q)
    // snapData.forEach(data => {
    //   this.collectionDocs['push'](data.data())
    // })

    // console.log(snapshot.data().count)
  }

  async multiqueryDifferentialSm(
    collectionName: string,
    backlinkID: string,
    backlink: string | null,
    searchVal1: string,
    lookVal1: any
  ) {
    const colName = collection(db, collectionName);
    const q = query(
      colName,
      where(backlinkID, '==', backlink),
      where(searchVal1, '==', lookVal1)
    );
    const snapshot = await getCountFromServer(q);
    return snapshot.data().count;
    // const snapData = await getDocs(q)
    // snapData.forEach(data => {
    //   this.collectionDocs['push'](data.data())
    // })

    // console.log(snapshot.data().count)
  }

  async getallDocs(
    collectionName: string,
    userLink: string,
    userID: string,
    limitNumber: number
  ) {
    let collectionDocs: DocumentData[] = [];
    console.log('Getting all docs!');

    const colName = collection(db, collectionName);
    const q = query(
      colName,
      where('deleted', '==', false),
      where(userLink, '==', userID),
      orderBy('timestamp', 'desc'),
      limit(limitNumber)
    );
    const docsAll = await getDocs(q);
    docsAll.forEach((data) => {
      collectionDocs['push']({
        id: data.id,
        data: data.data(),
      });
    });
    console.log(collectionDocs);
    return collectionDocs;
  }

  async sendResetMail(email: string) {
    return await sendPasswordResetEmail(auth, email);
  }

  async logIn(email: string, password: string) {
    await signInWithEmailAndPassword(auth, email, password);
  }

  async resetPassword(code: string, newPassword: string) {
    await confirmPasswordReset(auth, code, newPassword);
  }

  async logOut() {
    return await signOut(auth);
  }

  checkLoggedUser(): Observable<boolean> {
    let isloggedinStatus = false;
    console.log('Checking for user');

    return new Observable((observer) => {
      onAuthStateChanged(auth, (user) => {
        if (user) {
          return user;
        } else {
          return;
        }
      });
    });
  }

  async getCurrentUser() {
    return auth.currentUser;
  }
}
