import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormControl, FormControlName, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { FirebaseService } from '../services/firebase.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reset',
  standalone: true,
  imports: [ReactiveFormsModule, CommonModule],
  templateUrl: './reset.component.html',
  styleUrl: './reset.component.css'
})
export class ResetComponent {

  sendingData = false
  passwordResetSuccess = true
  message = ''
  

  constructor(private router: Router, private firebaseService: FirebaseService, private activatedRoute: ActivatedRoute){

  }

  resetPassword = new FormGroup({
    password: new FormControl('',[
      Validators.required,
      Validators.minLength(8)
    ]),
    conf_password: new FormControl('', [
      Validators.required,
      Validators.minLength(8)
    ])
  })

  backToHome(){

  }

  async resetPasswords(){

    this.sendingData = !this.sendingData

    this.message = ''
    console.log('Reset password working')

    if(this.resetPassword.value['password'] == null){
      // console.log(this.resetPassword.value['password'])
      this.sendingData = !this.sendingData
      return
    }

    if(this.resetPassword.value['password'] == undefined){
      // console.log(this.resetPassword.value['password'])
      this.sendingData = !this.sendingData
      return
    }

    if(this.resetPassword.value['conf_password'] == null){
      // console.log(this.resetPassword.value['conf_password'])
      this.sendingData = !this.sendingData
      return
    }

    if(this.resetPassword.value['conf_password'] == undefined){
      // console.log(this.resetPassword.value['conf_password'])
      this.sendingData = !this.sendingData
      return
    }

    if(this.resetPassword.value['password'] != this.resetPassword.value['conf_password']){
      this.message = 'The passwords are not matching. Please check.'
      this.sendingData = !this.sendingData
      return
    }

    const oobCode = this.activatedRoute.snapshot.queryParamMap.get('oobCode')
    console.log(oobCode)

    if(oobCode == null){
      this.message = 'The link has expired. Unable to reset password.'
      this.sendingData = !this.sendingData
      return
    }

    
      try{

      
        await this.firebaseService.resetPassword(oobCode, this.resetPassword.value['password'])
        this.sendingData = !this.sendingData
        this.message = 'Password reset successful. Please log in with new password.'
      

      
    }catch(e){
      this.sendingData = !this.sendingData
      this. message = 'Something went wrong. Please try again later.'
    }
    

    
    
  }   

  goToHome(){
    this.router.navigate([''])
  }

}
  





